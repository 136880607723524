import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HouseIcon from '@material-ui/icons/House';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange,deepPurple,indigo, blueGrey, teal } from '@material-ui/core/colors';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,

    },
    tabs: {
        backgroundColor: theme.palette.secondary.light,
        alignItems:'center',
    },
    typography: {
        display: 'block',
        marginTop: '1.2em',
        marginBottom: '1.2em'
    },
      title: {
        fontSize: 14,
        marginTop: '2em',
      },
      orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      },
      purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
      },
      indigo: {
        color: theme.palette.getContrastText(indigo[500]),
        backgroundColor: indigo[500],
      },
      teal: {
        color: theme.palette.getContrastText(teal[500]),
        backgroundColor: teal[500],
      },
      blueGrey: {
        color: theme.palette.getContrastText(blueGrey[500]),
        backgroundColor: blueGrey[500],
      },
  }));
  

  export default function Committee() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return(
        <div className={classes.root}>
            <AppBar position="static"  className={classes.tabs}>
                <Tabs 

centered
                    value={value} 
                    onChange={handleChange} 
                    aria-label="Committee Information">
                    <Tab icon={<HouseIcon/>} aria-label="Welcome" {...a11yProps(0)} />
                    <Tab icon={<SupervisorAccountIcon/>} aria-label="Members" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Typography variant="h5" component="h5" align="center">
                    Welcome to the Committee page
                </Typography>
                <Typography variant="body1" component="p" align="center" className={classes.typography}>
                We are here to make sure that the society continues to provide excellent educational meetings twice a year for both juniors in training and consultants.
                </Typography>
                <Typography variant="body1" component="p" align="center" className={classes.typography}>
                Everyone is welcome to join, and all members can have a say in how the society runs at the AGM (held in November)
                </Typography>
                <Typography variant="body1" component="p" align="center" className={classes.typography}>
                The Committee has 5 meetings a year, 2 on the day of the clinical meeting and one in January, March and September.  The host for the June meeting is invited to the Committee meeting in March and the host for the November meeting is invited to the Committee meeting in September. 
                </Typography>
                <Typography variant="body1" component="p" align="center" className={classes.typography}>
                The minutes for these meetings are avilable to download.
                </Typography>        
            </TabPanel>
            <TabPanel value={value} index={1}>                
                <Typography variant="h5" component="h5" align="center" className={classes.typography}>
                    Committee Members
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                     <Card>
                         <CardContent>
                         <Avatar className={classes.teal}>D</Avatar>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                President
                            </Typography>
                            <Typography variant="h6" component="h2">
                                Dr. Deepak Kalra
                            </Typography>
                            <Typography variant="body2" component="p">
                                deepakk4@hotmail.com
                            </Typography>
                         </CardContent>
                     </Card>
                    </Grid>
                    <Grid item xs={12}>
                    <Card>
                         <CardContent>
                         <Avatar className={classes.orange}>S</Avatar>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Secretary
                            </Typography>
                            <Typography variant="h6" component="h2">
                            Dr Subramanian Mahadevan-Bava
                            </Typography>
                            <Typography variant="body2" >
                            Russell's Hall Hospital, Dudley, West Midlands DY1 2HQ
                            </Typography>
                            <Typography variant="body2" >
                            Subramanian.Mahadevan@dgh.nhs.uk
                            </Typography>
                         </CardContent>
                     </Card>
                    </Grid>
                    <Grid item xs={12}>
                    <Card>
                         <CardContent>
                         <Avatar className={classes.blueGrey}>A</Avatar>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Treasurer
                            </Typography>
                            <Typography variant="h6" component="h2">
                            Dr Anabel Copeman
                            </Typography>
                            <Typography variant="body2" component="p">
                            New Cross Hospital, Wednesfield Road, Wolverhampton, WV10 0QP
                            </Typography>
                            <Typography variant="body2" >
                            acopeman@nhs.net
                            </Typography>
                         </CardContent>
                     </Card>
                    </Grid>

                    <Grid item xs={12}>
                     <Card>
                         <CardContent>
                         <Avatar className={classes.teal}>G</Avatar>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>  
                            Council Rep from RCPCH
                            </Typography>
                            <Typography variant="h6" component="h2">
                            Dr Gyan Sinha
                            </Typography>
                            <Typography variant="body2" component="p">
                            Walsall Manor Hospital,Moat Road, Walsall WS2 9PS
                            </Typography>
                            <Typography variant="body2" component="p">
                            sec.sinha@walsallhospitals.nhs.uk
                            </Typography>
                         </CardContent>
                     </Card>
                    </Grid>
                    <Grid item xs={12}>
                    <Card>
                         <CardContent>
                         <Avatar className={classes.blueGrey}>P</Avatar>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                            General Member
                            </Typography>
                            <Typography variant="h6" component="h2">
                            Dr Penny Davis
                            </Typography>
                            <Typography variant="body2" component="p">
                            Birmingham Children's Hospital, Steelhouse Lane, Birmingham B4 6NH
                            </Typography>
                            <Typography variant="body2" >
                            penny.davis@bch.nhs.uk
                            </Typography>
                         </CardContent>
                     </Card>
                    </Grid>
                    <Grid item xs={12}>
                    <Card>
                         <CardContent>
                         <Avatar className={classes.orange}>R</Avatar>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                            General Member/ Website Lead
                            </Typography>
                            <Typography variant="h6" component="h2">
                            Dr. Rajesh Sakremath
                            </Typography>
                            <Typography variant="body2" >
                            Princess Royal Hospital Telford, Apley Castle, Telford TF1 6TF
                            </Typography>
                            <Typography variant="body2" >
                            rajesh.sakremath@nhs.net
                            </Typography>
                         </CardContent>
                     </Card>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
}

