import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CardContent } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import EventNoteIcon from "@material-ui/icons/EventNote";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  card: {
    width: "100%",
  },
}));

export default function Meetings() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>2020</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Friday 6th November, 2020 (Virtual Meeting)
              </Typography>
              <Typography variant="body2" component="p">
                UHB Birmingham
              </Typography>
            </CardContent>
          </Card>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>2016</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Wednesday 15th June, 2016 (Junior Meeting)
              </Typography>
              <Typography variant="body2" component="p">
                Russell's Hall Hospital
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Friday 4th November, 2016
              </Typography>
              <Typography variant="body2" component="p">
                University Hospital of Coventry and Warwickshire
              </Typography>
            </CardContent>
          </Card>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>2015</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Wednesday 17th June, 2015 (Junior meeting)
              </Typography>
              <Typography variant="body2" component="p">
                Birmingham Children's Hospital
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Friday 6th November, 2015
              </Typography>
              <Typography variant="body2" component="p">
                Russell's Hall Hospital
              </Typography>
            </CardContent>
          </Card>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>2014</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Wednesday 18th June, 2014 (Junior meeting)
              </Typography>
              <Typography variant="body2" component="p">
                Russell's Hall Hospital
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <EventNoteIcon color="primary" />
              <Typography variant="h6" component="h6">
                Friday 5th December, 2014
              </Typography>
              <Typography variant="body2" component="p">
                Walsall Manor Hospital
              </Typography>
            </CardContent>
          </Card>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
