import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import StarIcon from "@material-ui/icons/Star";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function createData(membership, category, annualSubscription) {
  return { membership, category, annualSubscription };
}

const rows = [
  createData("Membership", "Category", "Annual Subscription"),
  createData(
    "Ordinary Membership",
    "Consultants in Paediatrics or allied specialities",
    "£20"
  ),
  createData(
    "Ordinary Membership",
    "Non-consultant career grade Staff Appointments ",
    "£20"
  ),
  createData(
    "Honorary Membership",
    "Retired professionals in the above categories ",
    "Free"
  ),
  createData(
    "Associate Membership",
    "Paediatricians in training (FY1 and 2, ST1-8)",
    "Free"
  ),
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  typography: {
    display: "block",
    marginTop: "1.1em",
    marginBottom: "1.1em",
  },
  list: {
    align: "left",
    marginTop: "1em",
    marginBottom: "1em",
  },
  table: {
    width: "80%",
  },
}));

function Membership() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        component="h2"
        className={classes.typography}
        align="center"
      >
        Welcome to the membership page
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        There are many reasons why you should join:-
      </Typography>
      <List>
        <ListItem>
          {" "}
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Education - 2 high quality excellent meetings every year" />{" "}
        </ListItem>
        <ListItem>
          {" "}
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Networking - Social" />{" "}
        </ListItem>
        <ListItem>
          {" "}
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="To provide bursaries to enable the development of training opportunities for junior staff" />{" "}
        </ListItem>
      </List>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        Subscriptions ensures all this continues.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        className={classes.typography}
        align="justify"
      >
        Subscriptions Categories
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.category}>
                <StyledTableCell align="left">{row.membership}</StyledTableCell>
                <StyledTableCell align="left">{row.category}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.annualSubscription}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        Should you have any questions with reference to subscriptions please
        contact the secretary.
      </Typography>
    </div>
  );
}

export default Membership;
