import React from 'react';


function PageNotFound() {
    return(
        <div>
            <h1>Oops! something went wrong</h1>
        </div>
    );
}

export default PageNotFound;