import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MailIcon from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '40ch',
      },
      
    },
  }));


function Contact() {
    const classes = useStyles();
    const [subject, setSubject] =useState("");
    const [message, setMessage] =useState("");

    function handleClick(e) {
        window.location.href = `mailto:subramanian.mahadevan@dgh.nhs.uk?subject=${subject}&body=${message}`;       
        e.preventDefault();
        console.log('The link was clicked.');
      }

      const handleSubjectChange = (e) => setSubject(e.target.value);
      const handleMessageChange = (e) => setMessage(e.target.value);


    return(
        <form className={classes.root} noValidate autoComplete="off" >

                <div><TextField id="subject" label="Subject"  required value={subject} variant="outlined" onChange={handleSubjectChange}/></div>
                <div><TextField id="message" label="Message" multiline rows="12" required value={message} variant="outlined" onChange={handleMessageChange}/> </div>     
                <Box p={1} bgcolor="background.paper">     
                <div><Button   variant="contained" color="secondary" onClick={handleClick} startIcon={<MailIcon />}>Submit</Button></div>
                </Box>   

        </form>
    );
}

export default Contact;