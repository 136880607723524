import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import BursaryApplicationForm from "../documents/BursaryApplicationForm.docx";
import Box from "@material-ui/core/Box";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function createData(location, poster, presentation) {
  return { location, poster, presentation };
}

const rows = [
  createData("Location", "Poster", "Oral Presentation"),
  createData("International", "Up to £400", "Up to £500"),
  createData("UK", "Up to £200", "Up to £300"),
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  typography: {
    display: "block",
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  table: {
    width: "100%",
  },
  button: { alignItems: "center" },
}));

function Bursaries() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        component="h2"
        className={classes.typography}
        align="center"
      >
        Annual Training/Travel Bursaries
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        The Midland Regional Paediatric Society (MRPS) offers a number of
        training/travel bursaries to trainees working in paediatrics and allied
        specialities within the West Midlands Deanery. Their purpose is to
        provide financial support to attend a UK or international medical
        scientific meeting, either to present a poster/paper or to acquire
        specific knowledge/skills. The maximum amount will depend on the
        location of the conference/meeting and type of presentation as outlined
        below:-
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.location}>
                <StyledTableCell align="left">{row.location}</StyledTableCell>
                <StyledTableCell align="left">{row.poster}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.presentation}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box m={2}>
        <Button
          variant="contained"
          color="secondary"
          href={BursaryApplicationForm}
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
        >
          Download Application Form
        </Button>
      </Box>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        Completed application forms should be sent to the honorary secretary of
        the society, Dr Subramanian Mahadevan-Bava, Consultant Paediatrician and
        Gastroenterologist, Russell's Hall Hospital, Dudley, West Midlands DY1
        2HQ. Bursaries will be awarded twice yearly in February and August. The
        closing date for receiving submissions are January 31st and July 31st,
        respectively.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        Please note that on the application form your request needs support from
        a full member of the MRPS stating clearly why funding should be
        considered. Clear, low budget proposals will be considered favourably
        due to small total monies available and the Society's wish to support a
        number of trainees each year.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        All candidates will be notified of the Committee's decision by February
        21st or August 21st. The decision is final.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        Trainees will only be permitted one scholarship within a 4 year period.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className={classes.typography}
        align="justify"
      >
        Successful applicants must submit a written report (500 - 1000 words) of
        the meeting, also explaining how they benefited, to the honorary
        secretary within one month of attendance at the meeting.
      </Typography>
    </div>
  );
}

export default Bursaries;
