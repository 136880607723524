import React from 'react';
import './App.css';
import Navigation from './components/Navigation'


function App() {
    return (
      <div>
        <Navigation/>
      </div>
  );
}

export default App;
