import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import CssBaseline from '@material-ui/core/CssBaseline';
import {BrowserRouter, Route, Switch,Link} from 'react-router-dom';
import Home from './Home';
import Meetings from './Meetings';
import Committee from './Committee';
import Bursaries from './Bursaries';
import Membership from './Membership';
import Contact from './Contact';
import PageNotFound from './PageNotFound';
import mrpslogo from '../img/mrpslogo2.png';
import blue from '@material-ui/core/colors/blue';
import Divider from '@material-ui/core/Divider';




const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: 'linear-gradient(45deg, #330867  30%, #30cfd0 90%)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    background: 'linear-gradient(45deg, #30cfd0  30%, #330867 90%)',
    color:'white',
    width: drawerWidth, 
    palette: {
      primary: blue, 
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  media: {

alignItems:'center',paddingLeft:'20%',position:'relative', top:'-30px'
  },
  
}));




function Navigation(props){
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [pageName, setPageName] = React.useState('MRPS');

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };

      const handleListItemClick = (page) => {
          if(pageName !== page)
            setPageName(page);

            if(mobileOpen)
            handleDrawerToggle();
        
      };
      

    const drawer = (
    <div>
        <div className={classes.toolbar} />
<div>
        <img 
        className={classes.media}
        src={mrpslogo} alt='mrps'
        title="MRPS"
      />
      </div>
 
        <List>
            <Divider variant="inset" component="li" />
            <ListItem button  key="home" component={Link} to="/home" onClick={() => handleListItemClick('Home')}> 
                <ListItemIcon><HomeIcon style={{ color: blue[50] }}/></ListItemIcon>             
                <ListItemText primary="Home" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem button  key="meetings" component={Link} to="/meetings"  onClick={() => handleListItemClick('Meetings')}>
                <ListItemIcon><PeopleIcon style={{ color: blue[50] }}/></ListItemIcon> 
                <ListItemText primary="Meetings" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem button  key="committee" component={Link} to="/committee"  onClick={() => handleListItemClick('Committee')}>
                <ListItemIcon><SupervisorAccountIcon style={{ color: blue[50] }}/></ListItemIcon> 
                <ListItemText primary="Committee" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem button key="bursaries" component={Link} to="/bursaries" onClick={() => handleListItemClick('Bursaries')}>
                <ListItemIcon><SchoolIcon style={{ color: blue[50] }}/></ListItemIcon> 
                <ListItemText primary="Bursaries" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem button key="membership" component={Link} to="/membership" onClick={() => handleListItemClick('Membership')}>
                <ListItemIcon><PersonAddIcon style={{ color: blue[50] }}/></ListItemIcon>  
                <ListItemText primary="Membership" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem button key="contact" component={Link} to="/contact" onClick={() => handleListItemClick('Contact Us')}>  
                <ListItemIcon><ContactMailIcon style={{ color: blue[50] }}/></ListItemIcon>              
                <ListItemText primary="Contact Us" />
            </ListItem>
            <Divider variant="inset" component="li" />
        </List> 
    </div>
    );


    return(
        <BrowserRouter>
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton 
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                        <MenuIcon/>
                    </IconButton>

                    <Typography variant="h6" noWrap>
                        {pageName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
                </Hidden>
            </nav> 
            <main className={classes.content}>
            <div className={classes.toolbar} />  
                    <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/home" exact component={Home}/>
                <Route path="/meetings" exact component={Meetings}/>
                <Route path="/committee" exact  component={Committee}/>
                <Route path="/bursaries" exact component={Bursaries}/>
                <Route path="/membership" exact component={Membership}/>
                <Route path="/contact" exact component={Contact}/>
                <Route component={PageNotFound}/>
                </Switch>              
            </main>
        </div>
        </BrowserRouter>
    );
}

export default Navigation;