import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import virtual_meeting from "../img/meeting.svg";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import November2020RegistrationForm from "../documents/November2020RegistrationForm.docx";
import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  card: {
    width: "100%",
  },
  typography: {
    display: "block",
    marginTop: "1.2em",
    marginBottom: "1.2em",
  },
  typographyHeading: {
    display: "block",
    marginTop: "1.2em",
    marginBottom: "1.2em",
  },
  list: {
    align: "left",
    marginTop: "1.2em",
    marginBottom: "1.2em",
  },
  table: {
    width: "100%",
    height:"250px"
  },
  media: {
    height: 250,
    backgroundSize: "280px 1800px",
  },
  button: {
    align:"center"
  },
}));

function Home() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
      <Typography
        variant="h4"
        component="h4"
        className={classes.typographyHeading}
        align="center"
        color="primary"
      >
        Welcome to the Midlands Regional Paediatric Society
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={virtual_meeting}
          title="MRPS Meeting November 2020"
        />
        <CardContent>
        <Grid container justify="center">
<Grid item xs={12}>
          <Typography
            variant="body1"
            color="primary"
            component="p"
            align="center"
            className={classes.typography}
          >
            MRPS meeting is on Friday, 6th November 2020. It will be a virtual event.
          </Typography>
          </Grid>
 <Box>
          <Button
              variant="contained"
              color="primary"
              href={November2020RegistrationForm}
              className={classes.button}
              startIcon={<CloudDownloadIcon />}
            >
              Download Registration Form
            </Button>
            </Box>

       </Grid>

         
        </CardContent>
      </Card>
 

      </Grid>
      <Grid item xs={12}>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h5"
            color="primary"
            align="center"
            className={classes.typography}
          >
            MRPS Meetings
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            align="center"
            component="p"
            className={classes.typography}
          >
            The Society has an early summer and Autumn meeting.
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            component="p"
            align="center"
            className={classes.typography}
          >
            The Summer meeting is on a 3rd Wednesday in June to coincide with
            the SpR Regional Training Day. It's an afternoon meeting. It is an
            opportunity for doctors in paediatric training to present clinical
            cases, audit etc.
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            component="p"
            align="center"
            className={classes.typography}
          >
            The Arthur Kendall Prize is presented for the best junior and senior
            presentation.
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            component="p"
            align="center"
            className={classes.typography}
          >
            This is an opportunity for all consultants in the region to
            encourage and support our paediatricians in training. There's
            usually a social gathering for a meal after the meeting.
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            component="p"
            align="center"
            className={classes.typography}
          >
            The Main meeting is held in November and is an all day meeting on a
            Friday. Both local and national speakers are invited. The meetings
            are designed so that everyone will find it a valuable use of their
            time. This all day meeting is an opportunity for consultant
            colleagues to network and to earn CPD points.
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            component="p"
            align="center"
            className={classes.typography}
          >
            If you are not already a member please click on the membership page
            and have a look. It's free for juniors and only £20 for consultants
          </Typography>
        </CardContent>
      </Card>
      </Grid>
      </Grid>
      
      <Snackbar open={open} 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={4000} onClose={handleClose}>
        <Alert severity="info" onClose={handleClose}>
          MRPS meeting is on Friday, 6th November 2020.
                  </Alert>
      </Snackbar>


     </div>
  );
}

export default Home;
